import React, { useState } from 'react';
import "./Navbar.css";

export default function Navbar(props) {
    const { route } = props
    const [mobileView, setMobileView] = useState(true);
    function handleToogle() {
        console.log("called")
        setMobileView(!mobileView)
    }
    return (
        <>
            <div className={mobileView ? 'open-close-bar bar' : 'open-close-bar'}>
                {mobileView ?
                    <i class="fa-solid fa-bars" onClick={handleToogle}></i> :
                    <i class="fa-solid fa-xmark" onClick={handleToogle}></i>
                }
            </div>
            <div className={mobileView ? 'navbar-section' : "navbar-section open"}>
                <div className='nav-items start'>
                    <img src='/Asserts/logo.png' alt='logo'></img>
                    <a href='/'>RN SAFETY & SECURITY SYSTEMS</a>
                </div>
                <ul className='nav-items mid'>
                    <li><a href='/' className={route === 'home' ? 'selected' : ''}>HOME</a></li>
                    <li><a href='/about' className={route === 'about' ? 'selected' : ''}>ABOUT</a></li>
                    <li><a href='/products' className={route === 'products' ? 'selected' : ''}>PRODUCTS</a></li>
                    <li><a href='/services' className={route === 'services' ? 'selected' : ''}>SERVICES</a></li>
                    <li><a href='/contact' className={route === 'contact' ? 'selected' : ''}>CONTACT</a></li>
                </ul>
                <div className='nav-items end'>
                    <a href='/'><i class="fa-solid fa-phone"></i> +91 900 337 0881</a>
                    <a href='/contact' className='enquire-btn'>Enquire Now</a>
                </div>
            </div>
        </>
    )
}
