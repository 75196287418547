export const AllProducts = [
    {
        product_id: "fire-alarm",
        product_name: "Fire Alarm",
        product_subtitle: "Conventional & Addressable System (EN54, VDS, UL)",
        products: [
            {
                id: "fa1",
                product_certificate: "UL, EN54, LPCB & VDS",
                product_website: "www.cooperfire.com",
                product_description: `<p>Based in Doncaster, Eaton’s fire systems business manufacture and supply complete fire systems, not just individual components. A complete system can be specified from a single source, confident in the knowledge that all system components have been specifically designed and tested to ensure that they are all fully compatible with each other and will function as a fully complete fire detection and alarm system.</p>`
            },
            {
                id: "fa2",
                product_certificate: "UL, FM, EN54, LPCB & VDS",
                product_website: "www.hochiki.in",
                product_description: `<p>Established early in the twentieth century, Hochiki has a distinguished heritage of specialist technological expertise which has gained the group its international status as one of the world’s leading manufacturers of commercial fire detection solutions. At present, Hochiki Europe builds in excess of 700,000 conventional and intelligent detectors and ancillaries per year at its purpose-built production facility.</p>`
            },
            {
                id: "fa3",
                product_certificate: "UL, FM, LPCB & EN54",
                product_website: "www.siemens.co.in",
                product_description: "<p>Siemens fire alarm systems provide unbeatable accuracy and ease-of-use for building personnel, installers, and firefighters. All the features that make them simple to handle are designed to reduce costs over the life of the system. From flexible architecture, to time-and-money-saving installation features, Siemens intelligent fire detection and fire alarm systems support a broad range of buildings, applications and markets.</p>"
            },
            {
                id: "fa4",
                product_certificate: "LPCB & EN54",
                product_website: "www.agnidevices.com",
                product_description: "<p>The quality standard maintained by Agni Devices Pvt. Ltd is of unmatched quality and it is this that has escalated the overall status of our company. There is no dearth of efficiency that the engineers have in the manufacturing of Fire Safety Product. For us, the size or the volume of the order does not act as a deterrent and not even the span of time within which the product should be made and delivered to the respective clients. At no stage, we shirk away from our adoption of the pristine version of quality.</p>"
            },
            {
                id: "fa5",
                product_certificate: false,
                product_website: "www.newagefireprotection.com",
                product_description: "<p>NewAge Fire Protection Industries Pvt. Ltd are specialist Manufacturers of Fire Protection Equipments and Systems. They have designed and developed specialized solutions for varied field of applications for Oil and Gas, Petrochemicals, Power Generation and Distribution, Steel, Fertilizer, Defence Services, Airports & Seaports, Civil and Professional Fire Services.</p>"
            },
        ]
    },
    {
        product_id: "public-addressing",
        product_name: "Public Addressing",
        product_subtitle: false,
        products: [
            {
                id: "pa1",
                product_certificate: false,
                product_website: "www.heinrichlimited.com",
                product_description: `
    <p>HEINRICH LIMITED is UK based company Head office based in London. HEINRICH is the smart choice, providing the most comprehensive range of solutions to meet the needs of today’s residential, commercial and industrial buildings, Airport, Metro, Hotels, Auditorium, Live Music Performance. HEINRICH solutions help customers achieve savings in energy, time and cost, all while enhancing safety.</p>
    <p>HEINRICH is leading manufacturer of Public address and Voice Alarm and 3D integrated Control System, HEINRICH has unrivalled experience in supplying systems and services to major projects spanning all All over the world to serve better with high quality product.</p>
    <p>HEINRICH’s comprehensive offering of Sound System, Public Address and Voice Alarm Systems pioneered the use of innovations such as digital signal processing, Class AB, Class D and Class H amplifiers and Ambient Noise Sensing, Built for environmentally demanding conditions such as rail and metro where high reliability is of paramount importance, HEINRICH was also the first to offer voice over IP based Long Line PA systems using the open VIPA software architecture.</p>
    <p><b>Products available in Analog, Digital and IP system.</b></p>
    `
            },
        ]
    },
    {
        product_id: "cctv-video-surveillance",
        product_name: "CCTV – Video Surveillance",
        product_subtitle: false,
        products: [
            {
                id: "cvs1",
                product_certificate: false,
                product_website: "www.univiewworld.in",
                product_description: `
                <p>Uniview is the pioneer and leader of IP video surveillance. Firstly introduced IP video surveillance to China, Uniview now is the third largest player in video surveillance in China. In 2015, Uniview has the 8th largest global market share.</p>
                <p>Uniview has complete IP video surveillance product lines including IP cameras, NVR, Encoder, Decoder, Storage and Client software and app, covering a diverse vertical markets including Retail, Building, Industry, Education, Commercial, City Surveillance etc.</p>
                <p>Our aim is to become a global leader in video surveillance industry which endeavors to build a safer world by providing professional, reliable and cutting-edge products and services.</p>
                <p><b>Products available in UL Range.</b></p>
                `
            },
            {
                id: "cvs2",
                product_certificate: false,
                product_website: "www.ambicam.com",
                product_description: `
                <p>Our Cloud Base video recording system allows you to capture every single movements of your past, days or week. Record your clips and share it with your loved ones also get an alert of the activities you care for.</p>
                <p><b>Products available in wifi camera.</b></p>
                `
            },
        ]
    },
    {
        product_id: "access-control-system",
        product_name: "Access Control System",
        product_subtitle: false,
        products: [
            {
                id: "ac1",
                product_certificate: false,
                product_website: "www.hidglobal.com",
                product_description: `
        <p><b>Panel, Card & Reader</b></p>
        <p>HID Global is the trusted leader in products, services and solutions related to the creation, management, and use of secure identities for millions of customers worldwide. Recognized for robust quality, innovative designs and industry leadership, HID Global is focused on creating customer value and is the supplier of choice for OEMs, integrators, and developers serving a variety of markets that include physical access control; IT security, including strong authentication/credential management; card personalization; visitor management; government ID; and identification technologies for a range of applications. HID Global is an ASSA ABLOY Group brand.</p>
        `
            },
            {
                id: "ac2",
                product_certificate: false,
                product_website: "www.idcubesystems.com",
                product_description: `
            <p>IDCUBE is uniquely positioned to offer a most comprehensive open platform physical security and identity management solution. It offers two distinct platforms Access360 and iNEST to cater to specific needs of Enterprise and Residential sectors respectively.</p>
            <p>Our award winning solutions are trusted worldwide and have been successfully deployed via more than 100 system Integrators across the globe taking security to a next level.</p>
            `
            },
            {
                id: "ac3",
                product_certificate: false,
                product_website: "www.securityshells.com",
                product_description: `
            <p>Security Shells was incorporated in 2010 in Pune, Maharashtra, India with a vision to provide Identity & Access Management Solutions to its customers spanning in different verticals from industrial & manufacturing, IT/ITES, Government & Defence Institutions, banks & financial Institutions, logistic businesses, education Institutions and hospitality Industry.</p>
            `
            }
        ]

    },
    {
        product_id: "intrusion-alarm-system",
        product_name: "Intrusion Alarm System",
        product_subtitle: false,
        products: [
            {
                id: "ias1",
                product_certificate: false,
                product_website: "www.dsc.com",
                product_description: `
                <p>DSC (Digital Security Controls) is a world leader in electronic security. Since the company’s genesis, the experts at DSC have been leading the way. From our revolutionary control panels, to our industry-leading IP alarm monitoring products and now to our sleek, contemporary self-contained wireless panels, DSC has always been front and center in the security space.</p>
                <p>DSC puts forth an unbeatable combination of leading-edge technology and industry experience. The results are RELIABLE, FLEXIBLE, DURABLE security solutions of the highest QUALITY that are easy to install, easy to use, and are easily UPGRADEABLE. It’s simple. We know this space better than anyone. And we have for over 30 years. So, when you need exceptional security products that work when it matters most, look to DSC.</p>
                <p><b>Products available in wired and wireless system.</b></p>
                `
            },
        ]
    },
    {
        product_id: "gas-suppression-system",
        product_name: "GAS Suppression System",
        product_subtitle: false,
        products: [
            {
                id: "gss1",
                product_certificate: false,
                product_website: "www.siemens.co.in",
                product_description: `
                <p>Sinorix 1230 – automated extinguishing solution based on chemical agent Novec 1230 Sinorix 1230 is the latest generation of our chemical extinguishing systems, offering a range of unique features and benefits. This integral solution from one source also comprises a comprehensive service portfolio – from risk assessment, system design, installation, and commissioning to maintenance. Sinorix 1230 is based on a long-term, sustainable technology that has one of the highest safety margins and ensures quick and reliable extinguishing without negative impact on the environment, especially on global warming. The 42-bar technology from Siemens is space-saving and assures maximum design flexibility.</p>
                `
            },
            {
                id: "gss2",
                product_certificate: false,
                product_website: "www.newagefireprotection.com",
                product_description: `
                <p>NewAge Fire Protection Industries Pvt. Ltd are specialist Manufacturers of Fire Protection Equipments and Systems. They have designed and developed specialized solutions for varied field of applications for Oil and Gas, Petrochemicals, Power Generation and Distribution, Steel, Fertilizer, Defence Services, Airports & Seaports, Civil and Professional Fire Services.</p>
                `
            },
            {
                id: "gss3",
                product_certificate: false,
                product_website: "www.komtes.com",
                product_description: `
                <p>komtes offer comprehensive protection covering all means to a quick and effective response to the fire; from detection systems and smoke control, zoning, autonomous suppression systems, fire control and extinguishing and manual means and additional accessories for the evacuation, among other.</p>
                `
            },
            {
                id: "gss4",
                product_certificate: false,
                product_website: "www.minimaxfire.in",
                product_description: `
                <p>We are well known and respected for distribution and integration of Fire & Safety Products for many reputed clients across the India and some countries that has supplies innovation to its customers over the years, we proudly serves MNC’s, corporate customers, hospitals, educational institutions, small businesses and individual customers everywhere. Real Value Safety, strives to offer exceptional services and supports many fire and safety product lines and our objective to be the best. We create strong bonds with our customers and make finding solutions the priority.</p>
                `
            },
        ]
    },
    {
        product_id: "fire-hydrants-system",
        product_name: "Fire Hydrants System",
        product_subtitle: false,
        products: [
            {
                id: "fh1",
                product_certificate: false,
                product_website: "www.newagefireprotection.com",
                product_description: `
            <p>NewAge Fire Protection Industries Pvt. Ltd are specialist Manufacturers of Fire Protection Equipments and Systems. They have designed and developed specialized solutions for varied field of applications for Oil and Gas, Petrochemicals, Power Generation and Distribution, Steel, Fertilizer, Defence Services, Airports & Seaports, Civil and Professional Fire Services.</p>
            <p><b>Products available in SS & Metal Gun</b></p>
            `
            },
            {
                id: "fh2",
                product_certificate: false,
                product_website: "www.agnidevices.com",
                product_description: `
            <p>The quality standard maintained by Agni Devices Pvt. Ltd is of unmatched quality and it is this that has escalated the overall status of our company. There is no dearth of efficiency that the engineers have in the manufacturing of Fire Safety Product. For us, the size or the volume of the order does not act as a deterrent and not even the span of time within which the product should be made and delivered to the respective clients. At no stage, we shirk away from our adoption of the pristine version of quality.</p>
            `
            }
        ]
    },
    {
        product_id: "fire-extinguisher",
        product_name: "Fire Extinguisher",
        product_subtitle: false,
        products: [
            {
                id: "fe1",
                product_certificate: false,
                product_website: "www.agnidevices.com",
                product_description: `
                <p>The quality standard maintained by Agni Devices Pvt. Ltd is of unmatched quality and it is this that has escalated the overall status of our company. There is no dearth of efficiency that the engineers have in the manufacturing of Fire Safety Product. For us, the size or the volume of the order does not act as a deterrent and not even the span of time within which the product should be made and delivered to the respective clients. At no stage, we shirk away from our adoption of the pristine version of quality.</p>
                `
            },
            {
                id: "fe2",
                product_certificate: false,
                product_website: "www.safetyfirstint.com",
                product_description: `
                <p>In giving the best service to our clients in the industrial, commercial, institutional and telecommunications sectors, we continue to thrive as a full-service fire protection installation and maintenance company.</p>
                `
            },
        ]
    },
    {
        product_id: "signages",
        product_name: "Signages",
        product_subtitle: false,
        products: [
            {
                id: "sag1",
                product_certificate: false,
                product_website: "http://www.heinrichlimited.com",
                product_description: `
                <p>HEINRICH LIMITED is UK based company Head office based in London. HEINRICH is the smart choice, providing the most comprehensive range of solutions to meet the needs of today’s residential, commercial and industrial buildings, Airport, Metro, Hotels, Auditorium, Live Music Performance. HEINRICH solutions help customers achieve savings in energy, time and cost, all while enhancing safety.</p>
                <p>HEINRICH is leading manufacturer of Public address and Voice Alarm and 3D integrated Control System, HEINRICH has unrivalled experience in supplying systems and services to major projects spanning all All over the world to serve better with high quality product.</p>
                <p>HEINRICH’s comprehensive offering of Sound System, Public Address and Voice Alarm Systems pioneered the use of innovations such as digital signal processing, Class AB, Class D and Class H amplifiers and Ambient Noise Sensing, Built for environmentally demanding conditions such as rail and metro where high reliability is of paramount importance, HEINRICH was also the first to offer voice over IP based Long Line PA systems using the open VIPA software architecture.</p>
                <p><b>Wall and Ceilling mounting emergency exit sign</b></p>
                `
            },
            {
                id: "sag2",
                product_certificate: false,
                product_website: "www.hochikieurope.com/firescape",
                product_description: `
                <p>Hochiki Europe, world leaders in fire detection manufacturing introduces a brand new concept to the UK market – an innovative new Addressable Emergency Lighting system, FIREscape®.</p>
                <p>FIREscape® is a unique, highly cost effective and environmentally friendly emergency lighting system based on LED technology and is the UK’s first to be fully intelligent.</p>
                <p>FIREscape® is based around an addressable, emergency lighting control panel with battery back-up and features addressable, self contained luminaries and signage connected via screened, extra-low voltage (40V) cabling.</p>
                <p><b>Poducts available in standalone with battery operator and addresible signages</b></p>
                `
            },
        ]
    }
]