import React, { useState } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import "./Contact.css";
import Footer from '../../Components/Footer/Footer';
import emailjs from 'emailjs-com';

export default function Contact() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        message: ''
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validate = () => {
        let errors = {};
        if (!formData.firstName) errors.firstName = 'First Name is required';
        if (!formData.lastName) errors.lastName = 'Last Name is required';
        if (!formData.phone) errors.phone = 'Phone number is required';
        if (!formData.email) errors.email = 'Email is required';
        if (!formData.message) errors.message = 'Message is required';
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setLoading(true);
            emailjs.send(
                "rnsafety-in",
                "template_0zv4inr",
                formData, "TE54FHedkByq9DN9q"
            )
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    alert('Email sent successfully!');
                    setLoading(false);
                    setFormData({
                        firstName: '',
                        lastName: '',
                        phone: '',
                        email: '',
                        message: ''
                    })
                }, (err) => {
                    console.log('FAILED...', err);
                    alert('Failed to send email. Please try again later.');
                    setLoading(false);
                });
        } else {
            alert('Please fill in all required fields correctly.');
        }
    };

    return (
        <div>
            <div className='contact-btn-fixed'>
                <a href='/#'><i class="fa-solid fa-phone"></i><p>+91 900 338 0881</p></a>
                <a href='/contact'><i class="fa-solid fa-envelope"></i><p>Enquiry Now</p></a>
            </div>
            <Navbar route="contact" />
            <div className='services-intro-section'>
                <div className='services-intro-container'>
                    <div className='service-title-card'>
                        <h1>Contact Us</h1>
                        <p>We're here to help you every step of the way.</p>
                    </div>
                    <img src='/Asserts/Icons/map.png' alt='contact'></img>
                </div>
            </div>
            <div className='contacts-section'>
                <div className='contact-content'>
                    <h1>General Questions & Orders</h1>
                    <p className='contact-content-p'>If you have any queries or questions, please email us anytime at <span>"rnsafety687@gmail.com"</span> or simply use the contact form. Alternatively, check out the details below to reach us directly.</p>
                    <div className='contact-channel-card-wrapper'>
                        <div className='contact-channel-card'>
                            <i className="fa-solid fa-location-dot"></i>
                            <p>22/27, Thiruneermalai Road, opposite, Tykot Hi-Tech Tool Room Ltd, Nagalkeni, Chennai - 44</p>
                        </div>
                        <div className='contact-channel-card'>
                            <i className="fa-solid fa-phone"></i>
                            <p>+91 900 337 0881 /<br /> +91 739 738 5068</p>
                        </div>
                    </div>
                </div>
                <form className='contact-form' onSubmit={handleSubmit}>
                    <h1>Please share some of your Basic details.</h1>
                    <div className='contact-form-input-pair'>
                        <input
                            type='text'
                            placeholder='First Name'
                            name='firstName'
                            value={formData.firstName}
                            onChange={handleChange}
                            style={{ marginRight: "10px" }}
                        />
                        <input
                            type='text'
                            placeholder='Last Name'
                            name='lastName'
                            value={formData.lastName}
                            onChange={handleChange}
                            style={{ marginLeft: "10px" }}
                        />
                    </div>
                    <div className='contact-form-input-pair'>
                        <input
                            type='Number'
                            placeholder='Phone'
                            name='phone'
                            value={formData.phone}
                            onChange={handleChange}
                            style={{ marginRight: "10px" }}
                        />
                        <input
                            type='Email'
                            placeholder='Email'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            style={{ marginLeft: "10px" }}
                        />
                    </div>
                    <textarea
                        type='text'
                        placeholder='Message'
                        name='message'
                        value={formData.message}
                        onChange={handleChange}
                    />
                    <button type='submit' disabled={loading}>
                        {loading ? <>Loading...</> : <>Submit Form <i className="fa-solid fa-arrow-right"></i></>}
                    </button>
                </form>
            </div>
            <Footer />
        </div>
    );
}
