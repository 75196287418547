import React from 'react';
import "./Footer.css"

export default function Footer() {
    return (
        <div className='footer-section'>
            <div className='footer-container'>
                <div className='footer-header'>
                    <div className='nav-items start'>
                        <img src='/Asserts/logo.png' alt='logo'></img>
                        <a href='/'>RN SAFETY & SECURITY SYSTEMS</a>
                    </div>
                </div>
                <div className='footer-items'>
                    <div className='footer-item'>
                        <h3>Contact Us</h3>
                        <div className='footer-contact-pair'>
                            <i class="fa-solid fa-envelope"></i>
                            <a href='/#'>rnsafety687@gmail.com</a>
                        </div>
                        <div className='footer-contact-pair'>
                            <i class="fa-solid fa-phone"></i>
                            <a href='/#'>+91 900 337 0881 / +91 739 738 5068</a>
                        </div>
                        <div className='footer-contact-pair'>
                            <i class="fa-solid fa-location-dot"></i>
                            <a href='https://www.google.com/maps?ll=13.031063,80.235266&z=14&t=m&hl=en&gl=IN&mapclient=embed&q=26JP%2BC4C+Chennai,+Tamil+Nadu' target='__blank'>22/27, Thiruneermalai Road,<br /> opposite to Tykot Hi-Tech Tool Room Ltd, <br />Nagalkeni, Chennai - 44</a>
                        </div>
                    </div>
                    <div className='footer-item-mid'>
                        <div className='footer-item'>
                            <h3>Products</h3>
                            <a href='/products/fire-alarm-system'>Fire Alarm System</a>
                            <a href='/products/public Addressing'>Public Addressing</a>
                            <a href='/products/cctv-video-surveillance'>CCTV – Video Surveillance</a>
                            <a href='/products/access-control-system'>Access Control System</a>
                            <a href='/products/intrusion-alarm-system'>Intrusion Alarm System</a>
                            <a href='/products/gas-suppression-system'>GAS Suppression System</a>
                            <a href='/products/fire-hydrants-system'>Fire Hydrants System</a>
                            <a href='/products/fire-extinguisher'>Fire Extinguisher</a>
                            <a href='/products/signages'>Signages</a>
                        </div>
                        <div className='footer-item'>
                            <h3>Services</h3>
                            <a href='/services'>Annual Maintenance Contracts</a>
                            <a href='/services'>Preventive Regular Checkups</a>
                            <a href='/services'>Breakdown Calls</a>
                            <a href='/services'>Consulting for Cost Effective Solutions</a>
                            <a href='/services'>Training</a>
                            <a href='/services'>Auditing</a>
                            <a href='/services'>Mock Drill</a>
                        </div>
                    </div>
                    <div className='footer-item'>
                        <h3>Navigation</h3>
                        <a href='/'>Home</a>
                        <a href='/about'>About</a>
                        <a href='/products'>Products</a>
                        <a href='/services'>Service</a>
                        <a href='/contact'>Contact</a>
                    </div>
                </div>
                <div className='footer-footer'>
                    <p>© 2024 www.nsafety.in. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}
