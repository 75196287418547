import React from 'react';
import "./PageNotFound.css";
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';

export default function PageNotFound() {
    return (
        <>
            <Navbar />
            <div className="page-not-found">

                <div className="content">
                    <h1>404</h1>
                    <p>Page Not Found</p>
                    <a href="/" className="home-link">Go to Homepage</a>
                </div>

            </div>

            <Footer />
        </>
    );
}
