import React from 'react'
import Navbar from '../../Components/Navbar/Navbar';
import "./Home.css";
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DotLottiePlayer } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';
import Footer from '../../Components/Footer/Footer';

export default function Home() {
    return (
        <div>
            <Navbar route={"home"} />
            <div className='home-intro-section'>
                <Carousel controls={false} indicators={false} pause={false}>
                    <Carousel.Item>
                        <div class="image-container">
                            <img src="/Asserts/Carousel/1.jpeg" alt='carousel 1' />
                        </div>
                        <Carousel.Caption>
                            <h1>CCTV – <br />Video Surveillance</h1>
                            <p>CCTV relies on strategic placement of cameras, and observation of <br />the camera’s input on monitors somewhere.</p>
                            <a className='view-btn' href='/products/cctv-video-surveillance'>View Product <i class="fa-solid fa-arrow-right"></i></a>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div class="image-container">
                            <img src="/Asserts/Carousel/2.jpg" alt='carousel 2' />
                        </div>
                        <Carousel.Caption>
                            <h1>Fire Extinguisher</h1>
                            <p>The quality standard maintained by Agni Devices Pvt. Ltd is of unmatched quality and it is this that has escalated the overall status of our company.</p>
                            <a className='view-btn' href='/products/fire-extinguisher'>View Product <i class="fa-solid fa-arrow-right"></i></a>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div class="image-container">
                            <img src="/Asserts/Carousel/3.jpg" alt='carousel 3' />
                        </div>
                        <Carousel.Caption>
                            <h1>Access <br />Control System</h1>
                            <p>HID Global is the trusted leader in products, services and solutions related to the creation, management, and use of secure identities for millions of customers worldwide.</p>
                            <a className='view-btn' href='/products/access-control-system'>View Product <i class="fa-solid fa-arrow-right"></i></a>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
                <div className='home-intro-features'>
                    <p><i class="fa-solid fa-shield"></i> Premium Quality</p>
                    <p><i class="fa-solid fa-circle-question"></i> Full Time Support</p>
                    <p><i class="fa-solid fa-piggy-bank"></i> Low Installation Charges</p>
                    <p><i class="fa-solid fa-award"></i> Comes with Warrenty</p>
                </div>
                <div className='customers-container' style={{ position: "relative", zIndex: 100 }}>
                    <h1>Our valuable Customers</h1>
                    <div class="logos">
                        <div class="logos-slide">
                            <img src='/Asserts/Customers/1.png' alt='customer 1'></img>
                            <img src='/Asserts/Customers/2.png' alt='customer 2'></img>
                            <img src='/Asserts/Customers/3.png' alt='customer 3'></img>
                            <img src='/Asserts/Customers/4.png' alt='customer 4'></img>
                            <img src='/Asserts/Customers/5.png' alt='customer 5'></img>
                            <img src='/Asserts/Customers/6.png' alt='customer 6'></img>
                            <img src='/Asserts/Customers/7.png' alt='customer 7'></img>
                            <img src='/Asserts/Customers/8.png' alt='customer 8'></img>
                            <img src='/Asserts/Customers/9.png' alt='customer 9'></img>
                            <img src='/Asserts/Customers/10.png' alt='customer 10'></img>
                            <img src='/Asserts/Customers/11.png' alt='customer 11'></img>
                            <img src='/Asserts/Customers/12.png' alt='customer 12'></img>
                            <img src='/Asserts/Customers/13.png' alt='customer 13'></img>
                        </div>
                        <div class="logos-slide">
                            <img src='/Asserts/Customers/1.png' alt='customer 1'></img>
                            <img src='/Asserts/Customers/2.png' alt='customer 2'></img>
                            <img src='/Asserts/Customers/3.png' alt='customer 3'></img>
                            <img src='/Asserts/Customers/4.png' alt='customer 4'></img>
                            <img src='/Asserts/Customers/5.png' alt='customer 5'></img>
                            <img src='/Asserts/Customers/6.png' alt='customer 6'></img>
                            <img src='/Asserts/Customers/7.png' alt='customer 7'></img>
                            <img src='/Asserts/Customers/8.png' alt='customer 8'></img>
                            <img src='/Asserts/Customers/9.png' alt='customer 9'></img>
                            <img src='/Asserts/Customers/10.png' alt='customer 10'></img>
                            <img src='/Asserts/Customers/11.png' alt='customer 11'></img>
                            <img src='/Asserts/Customers/12.png' alt='customer 12'></img>
                            <img src='/Asserts/Customers/13.png' alt='customer 13'></img>
                        </div>
                    </div>
                </div>
                <div className='our-premium-line-section' style={{ position: "relative", zIndex: 100 }}>
                    <h1>Our Products Line</h1>
                    <div className='home-product-wrapper'>
                        <div className='products-container'>
                            <div className='home-product-card'>
                                <img src='/Asserts/Home-Products/fire-alarm.jpg' alt='Fire Alarm System'></img>
                                <h3>Fire Alarm System</h3>
                                <p>A fire alarm system has a number of devices working together to detect and warn people through visual and audio appliances when smoke, fire, carbon monoxide</p>
                                <a href='/products/fire-alarm' className='view-btn center'>View Details <i class="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className='products-container'>
                            <div className='home-product-card'>
                                <img src='/Asserts/Home-Products/pa.jpg' alt='Public Addressing'></img>
                                <h3>Public Addressing</h3>
                                <p>A public address system (PA system) is an electronic amplification system with a mixer, amplifier and loudspeakers, used to reinforce a sound source.</p>
                                <a href='/products/public-addressing' className='view-btn center'>View Details <i class="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className='products-container'>
                            <div className='home-product-card'>
                                <img src='/Asserts/Home-Products/cctv.jpg' alt='CCTV – Video Surveillance'></img>
                                <h3>CCTV – Video Surveillance</h3>
                                <p>CCTV relies on strategic placement of cameras, and observation of the camera’s input on monitors somewhere. Surveillance of the public using CCTV is common in</p>
                                <a href='/products/cctv-video-surveillance' className='view-btn center'>View Details <i class="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className='products-container'>
                            <div className='home-product-card'>
                                <img src='/Asserts/Home-Products/ac.jpg' alt='Access Control System'></img>
                                <h3>Access Control System</h3>
                                <p>Access control systems perform authorization identification, authentication, access approval, and accountability of entities through login credentials including</p>
                                <a href='/products/access-control-system' className='view-btn center'>View Details <i class="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className='products-container'>
                            <div className='home-product-card'>
                                <img src='/Asserts/Home-Products/ias.jpg' alt='Intrusion Alarm System'></img>
                                <h3>Intrusion Alarm System</h3>
                                <p>A security alarm is a system designed to detect intrusion – unauthorized entry – into a building or area. Security alarms are used in residential, commercial, industrial</p>
                                <a href='/products/intrusion-alarm-system' className='view-btn center'>View Details <i class="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className='products-container'>
                            <div className='home-product-card'>
                                <img src='/Asserts/Home-Products/gss.jpg' alt='GAS Suppression System'></img>
                                <h3>GAS Suppression System</h3>
                                <p>Gas Suppression Systems are generally used in concealed rooms. The principle used by Gas Suppression System is by breaking the Fire Triangle, the fire ceases to exist.</p>
                                <a href='/products/gas-suppression-system' className='view-btn center'>View Details <i class="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className='products-container'>
                            <div className='home-product-card'>
                                <img src='/Asserts/Home-Products/fhs.jpg' alt='Fire Hydrants System'></img>
                                <h3>Fire Hydrants System</h3>
                                <p>In rural areas where municipal water systems are not available, dry hydrants are used to supply water for fighting fires. A dry hydrant is analogous to a standpipe.</p>
                                <a href='/products/fire-hydrants-system' className='view-btn center'>View Details <i class="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className='products-container'>
                            <div className='home-product-card'>
                                <img src='/Asserts/Home-Products/fh.jpg' alt='Fire Extinguisher'></img>
                                <h3>Fire Extinguisher</h3>
                                <p>It prevents the chemical reactions involving heat, fuel, and oxygen, thus extinguishing the fire. Sodium bicarbonate, regular or ordinary used on class B and C fires</p>
                                <a href='/products/fire-extinguisher' className='view-btn center'>View Details <i class="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className='products-container'>
                            <div className='home-product-card'>
                                <img src='/Asserts/Home-Products/ss.jpg' alt='Signages'></img>
                                <h3>Signages</h3>
                                <p>Signage is the design or use of signs and symbols to communicate a message to a specific group, usually for the purpose of marketing or a kind of advocacy.</p>
                                <a href='/products/signages' className='view-btn center'>View Details <i class="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='home-service-section' style={{ position: "relative", zIndex: 100 }}>
                    <div className='home-service-container'>
                        <div className='home-service-content'>
                            <h1>Services that We Provide</h1>
                            <div className='service-pair-container'>
                                <i class="fa-solid fa-check"></i>
                                <p>Annual Maintenance Contracts</p>
                            </div>
                            <div className='service-pair-container'>
                                <i class="fa-solid fa-check"></i>
                                <p>Preventive Regular Checkups</p>
                            </div>
                            <div className='service-pair-container'>
                                <i class="fa-solid fa-check"></i>
                                <p>Breakdown Calls</p>
                            </div>
                            <div className='service-pair-container'>
                                <i class="fa-solid fa-check"></i>
                                <p>Consulting for Cost Effective Solutions</p>
                            </div>
                            <div className='service-pair-container'>
                                <i class="fa-solid fa-check"></i>
                                <p>Training</p>
                            </div>
                            <div className='service-pair-container'>
                                <i class="fa-solid fa-check"></i>
                                <p>Auditing</p>
                            </div>
                            <div className='service-pair-container'>
                                <i class="fa-solid fa-check"></i>
                                <p>Mock Drill</p>
                            </div>
                            <a href='/services' className='view-btn service'>Read More <i class="fa-solid fa-arrow-right"></i></a>
                        </div>
                        <div className='home-service-img'>
                            <img src='/Asserts/service.jpg' alt='services'></img>
                        </div>
                    </div>
                </div>
                <div className='custom-contact-section' style={{ position: "relative", zIndex: 100 }}>
                    <div className='custom-contact-container'>

                        <div className='custom-contact-img'>
                            <DotLottiePlayer
                                src="/Asserts/contact.lottie"
                                autoplay
                                loop
                                className='custom-player-img'
                            >
                            </DotLottiePlayer>
                        </div>
                        <div className='custom-contact-content'>
                            <h1>Looking for a Custom Solution?</h1>
                            <p>We offer a wide range of products that can be customized to meet your specific needs and preferences. Our team is dedicated to providing personalized solutions, ensuring that each product is tailored to your exact requirements. Whether you need a unique design, specific features, or any other customization, we are here to assist you.</p>
                            <p>For placing custom orders or if you have any questions regarding our products and services, please feel free to reach out to us. Our customer service team is always ready to help and provide the information you need. Don't hesitate to contact us for any inquiries or to discuss how we can create the perfect product for you.</p>
                            <a href='/contact' className='view-btn cutome-home'>Contact us now <i class="fa-solid fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className='home-contact-section'>
                    <div className='home-contact-container'>
                        <p className='open-timing'>OPEN MON-FRI 8AM-4PM IST</p>
                        <h1>Still got a Question</h1>
                        <p>Feel free to contact us through any of the channels listed below.</p>
                        <div className='home-contact-pair'>
                            <i class="fa-regular fa-envelope"></i>
                            <p>rnsafety687@gmail.com</p>
                        </div>
                        <div className='home-contact-pair'>
                            <i class="fa-solid fa-phone"></i>
                            <p>+91 900 337 0881 / +91 739 738 5068</p>
                        </div>
                        <div className='contact-us-btn'>
                            <a href='/contact' className='view-btn service'>Contact Form <i class="fa-solid fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <div className='contact-btn-fixed'>
                <a href='/#'><i class="fa-solid fa-phone"></i><p>+91 900 338 0881</p></a>
                <a href='/contact'><i class="fa-solid fa-envelope"></i><p>Enquiry Now</p></a>
            </div>
        </div >
    )
}
