import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../Components/Navbar/Navbar';
import './Products.css';
import { AllProducts } from '../../Components/AllProducts.js';
import Footer from '../../Components/Footer/Footer.js';

export default function Products() {
    const { product_id } = useParams();

    const productCategory = AllProducts.find(category => category.product_id === product_id) || AllProducts.find(category => category.product_id === "fire-alarm");

    return (
        <div>
            <div className='contact-btn-fixed'>
                <a href='/#'><i class="fa-solid fa-phone"></i><p>+91 900 338 0881</p></a>
                <a href='/contact'><i class="fa-solid fa-envelope"></i><p>Enquiry Now</p></a>
            </div>
            <Navbar route="products" />
            <div className='services-intro-section'>
                <div className='services-intro-container'>
                    <div className='service-title-card'>
                        <h1>Products</h1>
                        <p>Explore our diverse range of high-quality products.</p>
                    </div>
                    <img src='/Asserts/Icons/products.png' alt='products'></img>
                </div>
            </div>
            <div className='products-section'>
                <div className='products-content-container'>
                    <h1>{productCategory?.product_subtitle || productCategory?.product_name}</h1>
                    {productCategory?.products.map(product => (
                        <div className='product-card-wrapper' key={product.id}>
                            <div className='product-card-container'>
                                <div className='product-images'>
                                    <div className='product-cover-img'>
                                        <img src={`/Asserts/Products/Product/${product.id}.jpg`} alt='product'></img>
                                    </div>
                                    <div className='product-company-img'>
                                        <img src={`/Asserts/Products/Company/${product.id}.jpg`} alt='company name'></img>
                                    </div>
                                </div>
                                <div className='product-details'>
                                    {product.product_certificate ?
                                        <div className='product-details-pair'>
                                            <h5>Product Certification: </h5>
                                            <p>{product.product_certificate}</p>
                                        </div> : ""}
                                    <div className='product-details-pair'>
                                        <h5>Product Range / Brand Website: </h5>
                                        <a href={`http://${product.product_website}`} target='_blank' rel='noopener noreferrer'>{product.product_website}</a>
                                    </div>
                                    <div className='product-desc' dangerouslySetInnerHTML={{ __html: product.product_description }}></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='products-navigator'>
                    <div className='products-navigator-items'>
                        <h1>Our Products</h1>
                        {AllProducts.map(category => (
                            <a
                                className={productCategory.product_id === category.product_id ? "selected" : ""}
                                key={category.product_name}
                                href={`/products/${category.product_id}`}
                            >
                                <i className="fa-solid fa-angles-right"></i>
                                <p>{category.product_name}</p>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
