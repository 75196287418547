import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import "./About.css";
import { DotLottiePlayer } from '@dotlottie/react-player';
import Footer from '../../Components/Footer/Footer';

export default function About() {
    return (
        <div>
            <div className='contact-btn-fixed'>
                <a href='/#'><i class="fa-solid fa-phone"></i><p>+91 900 338 0881</p></a>
                <a href='/contact'><i class="fa-solid fa-envelope"></i><p>Enquiry Now</p></a>
            </div>
            <Navbar route={"about"} />
            <div className='about-section'>
                <div className='about-intro-section'>
                    <h1>About Us</h1>
                    <p>Discover our journey and values.</p>
                </div>
                <div className='about-the-company'>
                    <div className='custom-contact-img'>
                        <DotLottiePlayer
                            src="/Asserts/contact.lottie"
                            autoplay
                            loop
                            className='custom-player-img'
                        >
                        </DotLottiePlayer>
                    </div>
                    <div className='custom-contact-content about'>
                        <h1>About the Company</h1>
                        <p>RN SAFETY AND SECURITY SYSTEMS Prides itself by providing best Supply, Erection, and Maintenance. We provide the latest Technology and equipment allowing us to give our customers the best services possible. We also do a follow up on all clients to ensure that all equipment supplied is efficiently running and serviced on time.</p>
                        <div className='company-features-container'>
                            <div className='company-features' style={{ marginLeft: "0px" }}>
                                <img src='/Asserts/Icons/award.png' alt='awards'></img>
                                <div className='comapny-features-content'>
                                    <h1>10+</h1>
                                    <p>Awards</p>
                                </div>
                            </div>
                            <div className='company-features'>
                                <img src='/Asserts/Icons/work.png' alt='works'></img>
                                <div className='comapny-features-content'>
                                    <h1>1000+</h1>
                                    <p>Works</p>
                                </div>
                            </div>
                            <div className='company-features'>
                                <img src='/Asserts/Icons/bulb.png' alt='ideas'></img>
                                <div className='comapny-features-content'>
                                    <h1>100+</h1>
                                    <p>Ideas</p>
                                </div>
                            </div>
                            <div className='company-features' style={{ marginRight: "0px" }}>
                                <img src='/Asserts/Icons/heart.png' alt='clients'></img>
                                <div className='comapny-features-content'>
                                    <h1>1000+</h1>
                                    <p>Clients</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='vision-section'>
                    <div className='vision-container'>
                        <div className='vision-title'>
                            <h1>Our Vision</h1>
                        </div>
                        <div className='vision-content'>
                            <p>Our vision is to set the benchmark for innovation and reliability in the safety and security sector. We aspire to leverage the latest technology and equipment to offer unparalleled services to our clients. Through continuous improvement and a customer-centric approach, we aim to establish long-term relationships, ensuring the optimal performance and timely servicing of all supplied equipment. We envision a safer and more secure environment for our clients, driven by our dedication to excellence and customer satisfaction.</p>

                        </div>
                    </div>
                </div>
                <div className='mision-section'>
                    <div className='mision-container'>
                        <div className='mision-title'>
                            <h1>Our Mision</h1>
                        </div>
                        <div className='mision-content'>
                            <p>At RN Safety and Security Systems, our mission is to be a leading provider in the industry, delivering excellence in Supply, Erection, and Maintenance of state-of-the-art technology and equipment. We are committed to ensuring the safety and security of our clients through the provision of top-quality services and solutions</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}
