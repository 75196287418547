import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Products from './Pages/Products/Products';
import Contact from './Pages/Contact/Contact';
import Services from './Pages/Services/Services';
import PageNotFound from './Pages/PageNotFound/PageNotFound';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<Home />} path='/' exact />
          <Route element={<About />} path='/about' exact />
          <Route element={<Products />} path='/products' exact />
          <Route element={<Products />} path='/products/:product_id' exact />
          <Route element={<Services />} path='/services' exact />
          <Route element={<Contact />} path='/contact' exact />
          <Route element={<PageNotFound />} path='/404' exact />
          <Route path="*" element={<PageNotFound />} /> {/* Wildcard route for 404 */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
