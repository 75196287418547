import React from 'react'
import Navbar from '../../Components/Navbar/Navbar';
import "./Services.css"
import Footer from '../../Components/Footer/Footer';

export default function Services() {
    return (
        <div>
            <div className='contact-btn-fixed'>
                <a href='/#'><i class="fa-solid fa-phone"></i><p>+91 900 338 0881</p></a>
                <a href='/contact'><i class="fa-solid fa-envelope"></i><p>Enquiry Now</p></a>
            </div>
            <Navbar route="services" />
            <div className='services-section'>
                <div className='services-intro-section'>
                    <div className='services-intro-container'>
                        <div className='service-title-card'>
                            <h1>Our Services</h1>
                            <p>Your satisfaction is our priority.</p>
                        </div>
                        <img src='/Asserts/Icons/service.png' alt='service'></img>
                    </div>
                </div>
                <div className='service-card-wrapper'>
                    <div className='service-card'>
                        <div className='service-img-wrapper'>
                            <img src='/Asserts/Icons/maintenance.png' alt='Annual Maintenance Contracts'></img>
                        </div>
                        <div className='service-content'>
                            <h3>Annual Maintenance Contracts</h3>
                            <p>Comprehensive yearly maintenance plans to ensure your systems run smoothly and efficiently.</p>
                        </div>
                    </div>
                    <div className='service-card'>
                        <div className='service-img-wrapper'>
                            <img src='/Asserts/Icons/checkups.png' alt='Preventive Regular Checkups'></img>
                        </div>
                        <div className='service-content'>
                            <h3>Preventive Regular Checkups</h3>
                            <p>Scheduled inspections to detect and address potential issues before they become major problems.</p>
                        </div>
                    </div>
                    <div className='service-card'>
                        <div className='service-img-wrapper'>
                            <img src='/Asserts/Icons/emerrgency.png' alt='emergency'></img>
                        </div>
                        <div className='service-content'>
                            <h3>Breakdown Calls</h3>
                            <p>Immediate support and repair services to quickly resolve unexpected equipment failures.</p>
                        </div>
                    </div>
                    <div className='service-card'>
                        <div className='service-img-wrapper'>
                            <img src='/Asserts/Icons/cost.png' alt='cost effective'></img>
                        </div>
                        <div className='service-content'>
                            <h3>Consulting for Cost-Effective Solutions</h3>
                            <p>Expert advice to help you find the most economical and efficient solutions for your operational needs.</p>
                        </div>
                    </div>
                    <div className='service-card'>
                        <div className='service-img-wrapper'>
                            <img src='/Asserts/Icons/training.png' alt='training'></img>
                        </div>
                        <div className='service-content'>
                            <h3>Training</h3>
                            <p>Specialized training programs to equip your team with the skills and knowledge to maintain and operate systems effectively.</p>
                        </div>
                    </div>
                    <div className='service-card'>
                        <div className='service-img-wrapper'>
                            <img src='/Asserts/Icons/auditing.png' alt='auditing'></img>
                        </div>
                        <div className='service-content'>
                            <h3>Auditing</h3>
                            <p>Thorough assessments of your systems and processes to ensure compliance and optimal performance.</p>
                        </div>
                    </div>
                    <div className='service-card'>
                        <div className='service-img-wrapper'>
                            <img src='/Asserts/Icons/drill.png' alt='mock drill'></img>
                        </div>
                        <div className='service-content'>
                            <h3>Mock Drill</h3>
                            <p>Simulated emergency exercises to prepare your team for real-life scenarios and enhance safety protocols.</p>
                        </div>
                    </div>
                </div>
                <div className='home-contact-section'>
                    <div className='home-contact-container'>
                        <p className='open-timing'>OPEN MON-FRI 8AM-4PM IST</p>
                        <h1>Still got a Question</h1>
                        <p>Feel free to contact us through any of the channels listed below.</p>
                        <div className='home-contact-pair'>
                            <i class="fa-regular fa-envelope"></i>
                            <p>rnsafety687@gmail.com</p>
                        </div>
                        <div className='home-contact-pair'>
                            <i class="fa-solid fa-phone"></i>
                            <p>+91 900 337 0881 / +91 739 738 5068</p>
                        </div>
                        <div className='contact-us-btn'>
                            <a href='/#' className='view-btn service'>Contact Form <i class="fa-solid fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </div>
    )
}
